<script>
  import { fade } from 'svelte/transition'
  import { q } from '../api.js'
</script>
{#if $q.n_loading > 0 }
  <div class="loading-indicator" transition:fade={{ duration:300 }} />
{/if}

<style>
.loading-indicator {

  background: rgba(98, 180, 98, 0.4);
  z-index: 100;

  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  width: 100%;
}

.loading-indicator:after {

  content: " ";

  z-index: 101;

  display: block;
  position: absolute;
  height: 4px;
  width: 100%;

  background: rgba(98, 180, 98, 0.8);

  transform-origin: 0% 0%;
  transform: scaleX(0);
  animation: loading 1.5s infinite;
  transition-timing-function: ease-in-out;
}

@keyframes loading {
  0% { transform: scaleX(0); }
  40% { transform: scaleX(1); }
  80% { transform: translateX(100%); }
}

.error-notification {
  position: fixed;
  z-index: 99;
  top: 16px;
  right: 16px;
  width: 50%;
}


</style>
