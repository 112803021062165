<script>

  import { fade } from 'svelte/transition'

  import { q } from '../api.js'

</script>
{#if $q.error }
	<!-- šis parādīs jebkuru api erroru -->
	<div class="error-notification notification is-warning" transition:fade={{ duration:300 }}>{ $q.error }</div>
{/if}

<style>
.error-notification {
  position: fixed;
  z-index: 99;
  top: 16px;
  right: 16px;
  width: 50%;

  background-color: hsl(48, 100%, 67%);
  color: rgba(0, 0, 0, 0.7);
  padding: 16px;
  border-radius: 5px;
}
</style>
