import { luminaires } from './stores'

import * as api from './api'

let update_timeout

export const updateLuminaires = () => {

  clearTimeout(update_timeout)

  api.list('get').then( luminaires.set )

  update_timeout = window.setTimeout(updateLuminaires, 30000)
}
