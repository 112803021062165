export default {
  // apiserver: 'https://citylight.teliko.com:3000',
  apiserver: 'https://citylight.spicausis.lv/api',
  // apiserver: 'https://dev.spicausis.lv/corstest.php?a=',
  //
  login: {
    email: 'api22@teliko.com',
    password: 'y6lo5uso',

  }
}

