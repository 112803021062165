<script>

  import { onMount } from 'svelte'
  import { fade } from 'svelte/transition'
  import { Router, Route, Link } from 'svelte-routing'
  import { globalHistory } from 'svelte-routing/src/history'

  import * as api from './api'

  import LoadingIndicator from './generic/LoadingIndicator.svelte'
  import ErrorPopup from './generic/ErrorPopup.svelte'

  import { updateLuminaires } from './luminaires'
  import { location } from './stores'

  import Gains from './Gains.svelte'
  import Tech from './Tech.svelte'
  import Video from './Video.svelte'

  import { is_video_playing } from './stores'

  import "../scss/main.scss"

  import InlineSVG from 'svelte-inline-svg'

  export let url = ''

  onMount(() => globalHistory.listen( (h) => {
    $location = h.location.pathname
    document.body.className = body_class(h.location.pathname)
  }))

  const active_class = (loc, link_loc) => ( loc === link_loc ? 'is-active' : 'not-active' )

  const body_class= (loc) => {
    const l = loc.replace(/[/]/, '')
    return 'body-' + (l ? l : 'default')
  }

  document.body.className = body_class(window.location.pathname)

  updateLuminaires()

</script>

<LoadingIndicator />
<ErrorPopup />

<Router url={url}>
  <div class="layout">
    <Route path="/" component={Gains} />
    <Route path="/tehnologijas" component={Tech} />
    <Route path="/video" component={Video} />
    {#if ! $is_video_playing}
    <nav transition:fade>
      <Link class={active_class($location, '/')} to="/"><InlineSVG src="svg/icon-info.svg" />Informācija</Link>
      <Link class={active_class($location, '/tehnologijas')} to="/tehnologijas"><InlineSVG src="svg/icon-tech.svg" />Tehnoloģijas</Link>
      <Link class={active_class($location, '/video')} to="/video"><InlineSVG src="svg/icon-video.svg" />Video</Link>
    </nav>
    {/if}
  </div>
</Router>
