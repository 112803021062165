
import { writable } from 'svelte/store'

import config from './config'

export const q = writable({
  // veicot jebkuru API pieprasījumu, storē var sekot līdzi statusam, 
  // masīvos būs pēc endpointa kejā boolean lauki:
  // api.list('some_list')
  //   $q.is_ready.some_list
  //   $q.is_loading.some_list
  //
  // un:
  //
  //   $q.status.some_list:
  //
  //   loading, ready, failed
  //   

  error: null, // pēdējā kļūda
  n_loading: 0,
  status: {},
  is_ready: {},
  is_loading: {},
})

export const STATUS = {
  READY: 'ready',
  FAILED: 'failed',
  LOADING: 'loading',
}

export const url = (endpoint) => {
  if (endpoint.substring(0, 8) === 'https://') {
    return endpoint
  }
  return `${config.apiserver}/${endpoint}`
}



export const call = async function call (endpoint, method, params = null) {

  let params_ue = ''
  if (params) {
    params_ue = Object.keys(params).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`).join('&')
  }

  const use_post = method === 'POST'
  const fetch_params = use_post ? {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: params_ue,
  } : { headers: {
    'Content-Type': 'text/plain',

  } }

  const fetch_url = url(endpoint) + (
    (use_post || ! params) 
    ? ''
    : ('?' + params_ue)
  )

  q.update( q => ({
    n_loading: q.n_loading + 1,
    status:     { ...q.status,     [endpoint]: STATUS.LOADING },
    is_ready:   { ...q.is_ready,   [endpoint]: false },
    is_loading: { ...q.is_loading, [endpoint]: true },
  }))

  let response_text
  try {
    const res = await fetch (fetch_url, fetch_params)
    response_text = await res.text()

    if ( ! response_text) throw("empty_response")
    if (response_text[0] === '<') throw("responding_with_html")

    let rjs = {};
    do {

      if (response_text[0] !== '{' && response_text[0] !== '[') throw(response_text)

      rjs = JSON.parse(response_text)

      if ( ! rjs.ok ) throw(rjs.error)

      rjs = rjs.data

    } while (false)

    q.update(q => ({
      n_loading: q.n_loading - 1,
      status:     { ...q.status,     [endpoint]: STATUS.READY },
      is_ready:   { ...q.is_ready,   [endpoint]: true },
      is_loading: { ...q.is_loading, [endpoint]: false },
    }))

    return rjs

  } catch (e) {

    const e_s = `${e}`

    q.update(q => ({
      ...q,
      n_loading: q.n_loading - 1,
      status:     { ...q.status,   [endpoint]: STATUS.FAILED },
      is_loading: { ...q.is_loading, [endpoint]: false },
      error: `${endpoint}: ${e_s}`,
    }))

    window.setTimeout(() => {
      q.update( q => ({ ...q, error: null }) )
    }, 6000)

    throw e
  }
}

export const post = async function post (endpoint, params) { return await call(endpoint, 'POST', params) }
export const get  = async function post (endpoint, params) { return await call(endpoint, 'GET', params) }


export const list = get

