import { writable } from 'svelte/store'

export const luminaires = writable( [] )

export const replaced_lamps = writable([
    ['Nātrijs, 70W',  70, 13],
    ['Nātrijs, 100W', 100, 135],
    ['Nātrijs, 150W', 150, 1442],
    ['Nātrijs, 250W', 250, 979],
    ['Nātrijs, 400W', 400, 70],

])

export const location = writable(window.location.pathname)

export const is_video_playing = writable(false)
