<script>

  import { onMount } from 'svelte'
  import { is_video_playing } from './stores'

  export let location = {}

  let video

  onMount(() => {

    video.addEventListener('play', () => is_video_playing.set(true))
    video.addEventListener('pause', () => is_video_playing.set(false))

  })

</script>

<div class="page-video">
  <video bind:this={video} controls>
    <source src="/video/citylight.1080p.mp4" />
  </video>
</div>

<style>
.page-video {
  flex: 0 0 100vw;
}
video{
  height: auto;
  width: 100%;
}
</style>
