<script>
  import { luminaires } from './stores'
  import { replaced_lamps } from './stores'

  import EkiiLogo from './EkiiLogo.svelte'

  let alt_stats = []

  export let location

  const space_decimals = (n) => typeof n === "string" ? n : n.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '<span class="spacer"></span>')

  function update_stats(lumi, repl) {
    if ( ! lumi.n ) return

    const min_date = new Date(lumi.min_date)
    const beginning_of_time = Date.UTC(min_date.getFullYear(), min_date.getMonth(), min_date.getDate()) // hms = 0
    const today = new Date
    const now = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) // hms = 0

    const days_diff = Math.floor((now - beginning_of_time) / (1000 * 60 * 60 * 24)) + 1

    let sum_w = 0
    repl.forEach(r => sum_w += r[1] * r[2])

    const na_paterins = sum_w * 1.15 / 1000 // kW

    const work_hours = (days_diff <= 2) ? 13.1 * days_diff : (lumi.duty_cycle * days_diff * 24)
    const g = work_hours * na_paterins - lumi.total_consumption
    const old_consumption = work_hours * na_paterins
    alt_stats = [
      ['Darba stundas', work_hours.toFixed(1), 'h' ],

      ['CO2 emisiju samazinājums', (g * 0.23314), 'kg' ],

      ['Elektroenerģijas patēriņš pēc projekta realizācijas', lumi.total_consumption, 'kWh' ],

      ['Elektroenerģijas patēriņš pirms projekta realizācijas', (work_hours * na_paterins), 'kWh' ],

      ['Elektroenerģijas ietaupījums %', ((old_consumption - lumi.total_consumption) * 100 / old_consumption), '%' ],

      ['Elektroenerģijas ietaupījums', (old_consumption - lumi.total_consumption), 'kWh' ],

    ]

  }

  $: update_stats($luminaires, $replaced_lamps)

</script>

<div class="page-gains">
  <p class="title">Emisijas kvotu izsolīšanas instrumenta Siltumnīcefekta gāzu emisiju samazināšana ar viedajām pilsētvides tehnoloģijām"</p>
  <p class="subtitle">Projekts Nr. EKII-3/21 "Viedo tehnoloģiju ieviešana Rīgas pilsētas apgaismojuma sistēmā"
  <div class="stats">
  {#each alt_stats as s}
    <div class="stat">
      <div class="stat-text">{ s[0] }</div>
      <div class="stat-value">{@html space_decimals(s[1]) }<span class="stat-measure">{ s[2] }</span></div>
    </div>
  {/each}
  </div>
</div>
<EkiiLogo />

<style>
  .page-gains {
    width: 61%;
    min-height: 100vh;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 133px 124px;

    color: white;
  }

  .title, .subtitle {
    max-width: 50rem;
  }

  .title {
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 0.5rem;
  }
  .subtitle {
    font-size: 26px;
    font-weight: 300;
    margin-bottom: 105px;
  }

  .stat-text {
    font-size: 22px;
  }
  .stat-value {
    font-weight: 600;
    font-size: 66px;
  }
  .stat-measure {
    margin-left: 0.5rem;
  }

  .stats {
    display: flex;
    flex-wrap: wrap;
  }
  .stat {
    flex: 0 0 50%;
    overflow: hidden;
  }
  .stat {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 2rem 0;
  }
  .stat-measure {
    font-size: 50%;
    font-weigth: 300;
  }
</style>
